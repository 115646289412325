import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import firebase from '../../firebase';
import Coupon from './coupon/coupon';
import AddCouponForm from './addCouponForm/addCouponForm';

import './coupons.css';

export default function Coupons() {
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        const unSubscribe = firebase.firestore().collection('coupons').onSnapshot((querySnapshot) => {
            const newCoupons = querySnapshot.docs.map((doc) => {
                return {id: doc.id, ...doc.data()};
            });
            setCoupons(newCoupons);
            return () => unSubscribe();
        });
    }, []);

    return (
        <div>
            <h3>Coupons </h3>
            <AddCouponForm />

            <Container fluid>
                <Row className="justify-content-md-center">
                    {coupons.map(coupon => 
                        <Col className='coupon' key={coupon.id}>
                            <Coupon coupon={coupon} />
                        </Col>

                    )}
                </Row>

            </Container>
        </div>
    );
}