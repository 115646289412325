import React from 'react';

import Logout from '../logout/logout';

import './header.css';

export default function Header() {
    return (
        <header>
            <h1>Jennifer Coupons</h1>
            <Logout className='logout' />
        </header>
    );
}