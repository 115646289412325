import React from 'react';
import firebase from "firebase/app";
import { Box, Button } from '@material-ui/core';

import './login.css';

export default function Login() {
    var provider = new firebase.auth.GoogleAuthProvider();
    function signIn() {
      firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        // /** @type {firebase.auth.OAuthCredential} */
        // var credential = result.credential;
    
        // // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = credential.accessToken;
        // // The signed-in user info.
        // var user = result.user;
        // ...
      }).catch((error) => {
        // // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // // ...
      });
    }
  
    return (
        <Box component="div" className="login">
            <Button variant="contained" onClick={signIn}>Sign in with Google</Button>
        </Box>
    );
}