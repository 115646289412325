import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import Header from '../header/header';
import Coupons from '../coupons/coupons';

import './homepage.css';

export default function Homepage() {

    return (
        <div className='homepage'>
            <Header />
            <Coupons />
        </div>
    );
}