import React from 'react';
import firebase from '../../../firebase';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { blue, green } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import './coupon.css';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: blue[100],
    },
  }));

export default function Coupon(prop) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleRedeemClick = () => {
      firebase.firestore().collection('coupons').doc(prop.coupon.id).update({
        isRedeemed: true,
      });
    }
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
  
    return (
        <Card className={classes.root}>
            <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    {firebase.auth().currentUser.displayName.split(" ").map((name) => name.substr(0,1)).join(" ")}
                </Avatar>
            }
            action={
                <IconButton aria-label="settings">
                <MoreVertIcon />
                </IconButton>
            }
            title={prop.coupon.title}
            subheader={prop.coupon.timeCreated?.toDate().toString()}
            />
            {/* <CardMedia
            className={classes.media}
            image=""
            title="couponImage"
            /> */}
            <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
                {prop.coupon.description}
            </Typography>
            </CardContent>
            <CardActions disableSpacing>
              {!prop.coupon.isRedeemed && <IconButton onClick={handleRedeemClick}>
                <FavoriteIcon />
            </IconButton>}
            <IconButton aria-label="share">
                <ShareIcon />
            </IconButton>
            <IconButton
                className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <Typography paragraph>
                {prop.coupon.note}
                </Typography>
                <Typography>
                </Typography>
            </CardContent>
            </Collapse>
        </Card>
    );
}