import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import firebase from '../../../firebase';

export default function AddCouponForm() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
      const db = firebase.firestore();
      db.collection('coupons').doc().set({
        title: title,
        description: description,
        isRedeemed: false,
        note: "",
        sharedWith: [],
        timeCreated: firebase.firestore.FieldValue.serverTimestamp(),
        creator: firebase.auth().currentUser.uid,
    })
    .then(function() {
        console.log("Document successfully written!");
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
    setOpen(false);
    
  }

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
            <AddIcon />
        </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new Coupon</DialogTitle>
        <DialogContent>
          <DialogContentText>
            New coupon will be added with the current signed in user.
          </DialogContentText>
          <TextField
            autoFocus
            id="title"
            margin="dense"
            label="Coupon Title"
            onChange={e => setTitle(e.currentTarget.value)}
            type="text"
            fullWidth
            value={title}
          />
        <TextField
            id="description"
            margin="dense"
            label="Coupon Description"
            onChange={e => setDescription(e.currentTarget.value)}
            type="text"
            fullWidth
            value={description}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}