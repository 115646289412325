import React from 'react';
import firebase from "firebase/app";
import Button from '@material-ui/core/Button';

import './logout.css';

export default function Logout() {
    function signOut() {
        firebase.auth().signOut().then(() => {
            console.log("%cSign out successful.", "color: green");
        }).catch((error) => {
            console.log("%cError signing out.", "color: red");
        });
    }

    return(
        <div className='logout'>
            <Button variant="contained" onClick={signOut}>Sign Out</Button>
        </div>
    );
}